<template>
  <div class="out-wrapper">
    <div class="nav-wrapper">
      <div class="logo-wrapper" @click="$router.push('product')">
        <img src="@/assets/logo/logo.png" alt="" />
      </div>
      <div class="menu-wrapper">
                <div class="menu-nav">
                    <div class="menu-item" :class="menu.url == $route.path ? 'active' : ''" v-for="(menu, index) in topMenus" :key="index">
                        <router-link :to="menu.url">
                            <i class="iconfont" v-if="menu.icon">{{ menu.icon }}</i>
                            <span>{{ menu.title }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
      <div class="nav-right-box">
        <div class="search-box"></div>
        <div class="btn-wrapper">
          <div class="language-wrapper">
            <div class="language">
              <i class="iconfont icon-btn" v-show="language == 'zh'"
                >&#xe6444;</i
              >
              <i class="iconfont icon-btn" v-show="language == 'en'"
                >&#xe6445;</i
              >
            </div>
            <div class="international-panel">
              <div
                class="panel-item"
                @click="handleChaneLanguage('zh')"
                :class="language == 'zh' ? 'disabled' : ''"
              >
                <span>简体中文</span>
              </div>
              <div
                class="panel-item"
                @click="handleChaneLanguage('en')"
                :class="language == 'en' ? 'disabled' : ''"
              >
                <span>English</span>
              </div>
            </div>
          </div>
          <!-- <div class="right-btn" v-if="!userInfo" @click="SHOW_OR_HIDE">
            <div>
              <i class="iconfont">&#xe61f;</i>
              <span> {{$t("navMenus.btn.connect")}} </span>
            </div>
          </div> -->
          <div class="right-btn" v-if="!userInfo" @click="twitterLogin()">
            <div>
              <!-- <i class="iconfont">&#xe61f;</i> -->
              <span> {{ $t("navMenus.btn.twitter") }} </span>
            </div>
          </div>
          <div class="login-wrapper" v-if="userInfo">
            <div class="login-btn">
              <div class="wallet-wrapper">
                <i class="iconfont">&#xe61f;</i>
              </div>
              <div class="wallet-address">
                <span>{{ userInfo.nickName }}</span>
                <i
                  class="iconfont arrow"
                  :class="walletInfoShow ? 'rotation' : ''"
                  >&#xe87e;</i
                >
              </div>
              <div
                class="login-panel"
                :class="walletInfoShow ? 'login-active' : ''"
              >
                <!-- <div class="panel-item" @click="copy()">
                                  <i class="iconfont">&#xeb4e;</i>
                                <span>{{ $t("navMenus.other.copy") }}</span>
                            </div> -->
                <div class="panel-item" @click="goto('/userCenter')">
                  <i class="iconfont">&#xeb4e;</i>
                  <span>{{ $t("navMenus.other.usercenter") }}</span>
                </div>
                <div class="panel-item" @click="logout()">
                  <i class="iconfont">&#xe651;</i>
                  <span>{{ $t("navMenus.other.logout") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import MyModal from "@/components/MyModal";
import networks from "@/assets/json/networks.json";
import Drawer from "@/components/Drawer";
import { thirdLogin } from "@/api/user";
export default {
  name: "HeaderNav",
  components: { MyModal, Drawer },
  data() {
    return {
      mobileMenu: "#icon-caidan1",
      mobileMenuIsShow: false,
      isShowBatchMenu: false,
      isShowCommonMenu: false,
      isShowWallet: false,
      clientHeight: 0, //视口高度
      display: false,
      drawerWidth: "100%",
      mobileMenus: [],
      topMenus: [
        {
            title: this.$t("navMenus.menus.home"),
            icon: null,
            url: "/web3Tools",
        },
        {
          title: this.$t("navMenus.menus.product"),
          icon: null,
          url: "/product",
        },
        {
          title: this.$t("navMenus.menus.pay"),
          icon: null,
          url: "/pay",
        },      
        {
          title: this.$t("navMenus.menus.account"),
          icon: null,
          url: "/twitterAccount",
        }, 
      
        // {
        //     title: this.$t("navMenus.menus.dematch"),
        //     icon: null,
        //     url: "/deMatchTools",
        // },
      ],
      menuIsShow: false,
      walletInfoShow: false,
      visible: false,
      blockNetworks: null,
      chainId: null,
      currentNetName: null,
      language: "zh",
      userInfo:null,
    };
  },
  methods: {
    ...mapActions("wallet", ["initData"]),
    ...mapMutations("wallet", [
      "SHOW_OR_HIDE",
      "LOGOUTED",
      "UPDATE_WEB3_INFO",
      "INIT_LOGIN_INFO",
    ]),
    async twitterLogin() {
      let { data } = await thirdLogin({ type: "twitter" });
      if (data.code == 1) {
        // this.$toast.success("推特登录成功");
        window.location.href = data.data;
      } else {
        this.$toast.error(this.$t("usercenter.tip.get_twitter_url_failed"));
      }
    },
    connectWallet() {
      this.isShowWallet = false;
      this.SHOW_OR_HIDE();
    },
    menuSwitch() {
      this.mobileMenuIsShow = !this.mobileMenuIsShow;
      // console.log(this.mobileMenuIsShow);
      this.$refs.transition.performTransition();
    },
    goto(url) {
      if (this.mobileMenuIsShow) this.menuSwitch();
      this.isShowBatchMenu = false;
      this.isShowCommonMenu = false;

      if (this.$route.path == url) {
        return;
      }
      this.$router.push({
        path: url,
      });
    },
    handleChaneLanguage(locale) {
      let language = localStorage.getItem("language");
      if (locale !== language) {
        this.$i18n.locale = locale;
        localStorage.setItem("language", locale);
        window.location.reload();
      }
    },
    copy() {
      this.$copyText(this.netInfo.address)
        .then(() => {
          // alert(this.$t("navMenus.other.copySuccess"))
          this.$toast.success(this.$t("navMenus.other.copySuccess"));
        })
        .catch(() => {
          this.$toast.error(this.$t("navMenus.other.copyError"));
        });
    },
    logout() {
      this.LOGOUTED();
    },
    showOrHideMenu() {
      // console.log("show hide");
      this.menuIsShow = !this.menuIsShow;
    },
    showOrHideWalletInfo() {
      this.walletInfoShow = !this.walletInfoShow;
    },
    showModal() {
      this.visible = true;
    },
    closeModal() {
      this.visible = false;
    },
    metaMaskListener() {
      ethereum.on("accountsChanged", (accounts) => {
        // console.log("new account---",accounts[0])
        if (accounts.length === 0) {
          // MetaMask is locked or the user has not connected any accounts
          console.log("Please connect to MetaMask.");
        } else if (accounts[0] !== this.netInfo.address) {
          this.UPDATE_WEB3_INFO({
            address: accounts[0],
            chainId: this.chainId,
          });
          window.location.reload();
        }
      });
      ethereum.on("chainChanged", (chainId) => {
        console.log("new chainId---", web3.utils.hexToNumber(chainId));
        this.UPDATE_WEB3_INFO({
          address: this.netInfo.address,
          chainId: web3.utils.hexToNumber(chainId),
        });
        window.location.reload();
      });
    },
    async switchNet(item) {
      // console.log(item)
      try {
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.numberToHex(item.chainId) }],
        });
      } catch (switchError) {
        console.log("switch network error:", switchError);
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: web3.utils.numberToHex(item.chainId),
                  chainName: item.name,
                  rpcUrls: [item.rpcUrl] /* ... */,
                  nativeCurrency: {
                    name: item.token,
                    symbol: item.token, // 2-6 characters long
                    decimals: 18,
                  },
                  blockExplorerUrls: [item.BlockBrowser],
                },
              ],
            });
          } catch (addError) {
            console.log("add network error:", addError);
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    },
    closeWallet() {
      this.isShowWallet = false;
    },
  },

  computed: {
    ...mapState("wallet", [ "netInfo"]),
  },
  watch: {
    isShowWallet(newValue, oldValue) {
      console.log(newValue, oldValue);
      this.$refs.right_transition.performTransition();
      if (this.isShowWallet) {
        document.body.addEventListener("click", this.closeWallet, false);
      } else {
        document.body.removeEventListener("click", this.closeWallet);
      }
    },
  },
  mounted() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.clientHeight = h;
    this.initData(); //初始化小狐狸钱包组件
    // this.INIT_LOGIN_INFO();  //初始化用户信息到store
    this.blockNetworks = networks;
    let currentNetwork = null;
    // this.chainId = localStorage.getItem("chainId");
    // setTimeout(() => {
    //   this.chainId = this.netInfo.chainId;
    //   console.log(this.chainId);
    //   if (this.chainId) {
    //     for (let i = 0; i < this.blockNetworks.length; i++) {
    //       let item = this.blockNetworks[i];
    //       let nets = item.networks;
    //       for (let j = 0; j < nets.length; j++) {
    //         let net = nets[j];
    //         if (net.chainId == this.chainId) {
    //           this.currentNetName = net.name;
    //           // console.log(this.currentNetName)
    //           currentNetwork = net;
    //           break;
    //         }
    //       }
    //     }
    //     localStorage.setItem("currentNetwork", JSON.stringify(currentNetwork));
    //   }
    // }, 1000);
    let lang = localStorage.getItem("language");
    this.language = lang;
    // if (web3) {
    //   this.metaMaskListener();
    // }
    this.userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null
    // this.clientHeight = window.screen.availHeight;
    // this.$toast.success(this.clientHeight)
    // console.log("window height : ", this.clientHeight);
    // console.log(window.screen.availHeight)
    // window.alert(window.screen.availHeight)
    // let h = document.documentElement.clientHeight || document.body.clientHeight;
    // this.clientHeight = h;
    // console.log(h);

    // console.log("window.innerHeight : ", window.innerHeight);
    // this.clientHeight = h;
    // this.$toast.error(`${h}`);
  },
};
</script>

<style lang="scss" scoped>
* {
  color: #fff;
}

.out-wrapper {
  width: 100%;
  height: 80px;
  background: #0c122d; // max-width: 1300px;
  // min-width: 1200px;
  // background-color: #fff;
  // box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  min-width: 375px;
  position: fixed;
  top: 0px;
  z-index: 997;
  display: flex; // align-content: center;
  justify-content: center;
  flex-wrap: wrap; // border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  // position: relative;
  .logo-wrapper {
    cursor: pointer;
    margin-left: 40px;
    display: flex;
    align-items: center;
    img {
      // width: 180px;
      height: 40px;
    }
  }
  .nav-wrapper {
    position: relative;
    width: 100%;
    max-width: 1400px;
    height: 80px; // margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .menu-wrapper {
      display: flex;
      .menu-nav {
        // width: 500px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .menu-item {
          line-height: 80px;
          position: relative;
          a {
            // display: inline-flex;
            i {
              font-size: 20px;
              margin-right: 3px;
            }
            span {
              font-size: 18px;
              font-family: Gilroy-Regular-☞, Gilroy-Regular;
              font-weight: normal;
              color: rgba(255, 255, 255, 0.7);
            }
            &:hover {
              cursor: pointer;
              span {
                color: #fff;
              }
            }
          }
        }
        .menu-item { //:nth-child(2)
          margin: 0 50px;
        }
        .active {
          a {
            span {
              color: #fff;
            }
          }
        }
      }
    }
    .nav-right-box {
      // position: absolute;
      // right: 20px;
      // top: 0px;
      // width: 460px;
      // height: 80px;
      // line-height: 80px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .btn-wrapper {
        // width: 140px;
        display: flex;
        justify-content: space-evenly;
        align-items: center; // .language-wrapper .right-btn,
        .right-btn {
          // padding: 0px 16px;
          // background-color: $global-color;
          // border-radius: 20px;
          // font-size: 14px;
          // line-height: 40px;
          // min-width: 80px;
          cursor: pointer;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // span {
          //   color: #fff;
          // }
          // &:active {
          //   background-color: $global-check-color;
          // }
          width: 140px;
          height: 40px;
          // background: linear-gradient(123deg, #eb1e8d 0%, #ffbc10 100%);
          background: $global-color;
          border-radius: 30px;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          div {
            i {
              font-size: 24px;
              opacity: 1;
            }
            span {
              font-size: 18px;
              font-family: DM Sans-Medium, DM Sans;
              font-weight: 500;
              color: #ffffff;
              line-height: 20px;
            }
          }
        }
        .language-wrapper {
          position: relative;
          height: 80px;
          display: flex;
          align-items: center;
          .language {
            margin-right: 10px;
            cursor: pointer;
            i {
              font-size: 28px;
              color: $global-color;
            }
          }
          &:hover {
            .international-panel {
              display: block;
            }
          }
          .international-panel {
            display: none;
            width: 88px;
            height: 90px;
            background: rgba(255, 255, 255, 0.08);
            border-radius: 8px;
            box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
            position: absolute;
            right: -25px;
            top: 60px;
            padding: 4px 4px;
            &:hover {
              display: block;
            }
            .panel-item {
              margin-top: 2px; // width: 56px;
              height: 40px;
              line-height: 40px;
              border-radius: 8px; // background: #fdecee;
              display: flex;
              align-items: center; // justify-content:center;
              span {
                // display: inline-block;
                margin-left: 16px;
                width: 56px; // height: 20px;
                font-size: 14px; // font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400; // text-align: center;
                color: #fff;
                line-height: 20px;
                i {
                  // margin-left: 10px;
                  font-size: 16px;
                  margin-right: 10px;
                }
              }
              &:hover {
                // background: $global-bg-color;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 4px 4px 4px 4px;
                opacity: 0.4;
                cursor: pointer;
                // span {
                //   color: $global-color;
                //   i {
                //     color: $global-color;
                //   }
                // }
              }
            }
          }
        }
        .btn-item {
          // height: 80px;
          // line-height: 80px;
          margin-left: 10px;
          div {
            // display: inline-flex;
            // line-height: 80px;
            .icon-btn {
              font-size: 24px;
              color: #5d5e67;
            }
            &:hover {
              .icon-btn {
                color: $global-color;
              }
            }
          } //登录成功box
        }
        .network-div {
          display: flex;
          align-items: center;
          background-color: $global-bg-color;
          border: 1px solid $global-color;
          border-radius: 10px;
          height: 30px;
          padding: 0 10px;
          cursor: pointer;
          span {
            color: $global-color;
            font-size: 14px;
            margin-right: 5px;
            white-space: nowrap;
          }
          i {
            color: $global-color;
            font-size: 12px;
            transition: all 0.3s;
          }
        }
        .login-wrapper {
          margin-left: 10px;
          height: 80px;
          position: relative;
          cursor: pointer; // display: flex;
          // align-items: center;
          .login-btn {
            display: flex;
            align-items: center; // border: 1px solid #fba;
            height: 80px;
            .wallet-wrapper {
              width: 30px;
              height: 30px;
              background: #222843;
              opacity: 1;
              border: 4px $global-color solid; // border-image: linear-gradient(147deg, rgba(236, 37, 136, 1), rgba(255, 188, 16, 1)) 4 4;
              // border-radius: 4px;
              // border-image: linear-gradient(to bottom, #ff0000, #00ff00);
              border-radius: 50%;
              border-image-slice: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 22px;
              left: 7px;
              i {
                color: $global-color;
              }
            }
            .wallet-address {
              // display: inline-block;
              // line-height: 0%;
              background: rgba(255, 255, 255, 0.08);
              border: 1px solid rgba(255, 255, 255, 0.1);
              border-radius: 10px;
              padding: 5px 10px 5px 20px;
              font-size: 14px;
              font-weight: 600;
              color: #606266;
              margin-left: 27px;
              display: flex;
              align-items: center;
              i {
                margin-left: 5px;
                font-size: 12px;
                transition: all 0.3s;
              }
              .rotation {
                transform: rotate(180deg);
              }
            }
            &:hover {
              .wallet-address {
                .arrow {
                  transform: rotate(180deg);
                }
              }
              .login-panel {
                display: block;
              }
            }
            .login-panel {
              display: none;
              // width: 140px;
              // height: 90px;
              // background: #ffffff;
              // border-radius: 8px;
              // box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);

              width: 138px;
              height: 88px;
              background: rgba(255, 255, 255, 0.06);
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              border: 1px solid rgba(255, 255, 255, 0.05);

              position: absolute;
              right: 0; // left: -61px;
              top: 70px;
              padding: 4px 4px;
              &:hover {
                display: block;
              }
              .panel-item {
                margin-top: 2px; // width: 56px;
                height: 40px;
                line-height: 40px;
                border-radius: 8px; // background: #fdecee;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 5px;
                i {
                  font-size: 18px;
                  padding-right: 5px;
                } // justify-content:center;
                span {
                  // display: inline-block;
                  // margin-left: 16px;
                  // width: 56px;
                  // height: 20px;
                  font-size: 14px; // font-family: PingFangSC, PingFangSC-Regular;
                  font-weight: 400; // text-align: center;
                  color: #fff;
                  line-height: 20px;
                  i {
                    // margin-left: 10px;
                    font-size: 16px;
                    margin-right: 10px;
                  }
                }
                &:hover {
                  background: rgba(255, 255, 255, 0.1);
                  border-radius: 4px 4px 4px 4px;
                  opacity: 0.4;
                  // background: $global-bg-color;
                  cursor: pointer;
                  span {
                    color: #fff;
                    i {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
          .login-active {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  } // @media only screen and (max-width: 1000px) {
  //   .nav-wrapper {
  //     .mobile-menu-wrapper,
  //     .mobile-right-wrapper,
  //     .mobile-logo-wrapper {
  //       display: block;
  //     }
  //     .menu-wrapper,
  //     .nav-right-box {
  //       display: none;
  //     }
  //   }
  // }
}
</style>