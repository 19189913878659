import Web3 from 'web3/dist/web3.min.js'
import { userLogin, updateInfo } from '@/api/user'
let state = {
  isClose: false,
  isLogin: false,
  netInfo: {
    web3Instance: null,
    chainId: null,
    balance: null,
    address: null
  }
}

const actions = {
  //链接小狐狸钱包
  async connectMetaMaskAction ({ commit, dispatch }, payload) {
    if (!web3) {
      this._vm.$toast.error(
        'The Metamask extension is not installed in the current browser'
      )
      return
    }
    // this._vm.$toast.error("connectMetaMaskAction")
    // console.log("connectMetaMaskAction")
    const accounts = await window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(accounts => {
        const address = accounts[0]
        const chainId = window.ethereum.networkVersion
        window.web3 = new Web3(window.web3.currentProvider)

        // console.log(address);
        let isLogin = localStorage.getItem('isLogin')
        if (!isLogin) {
          dispatch('signatureForLogin', { address, chainId, isSwitch: false })

          // commit("CONNECTED_METAMASK", { address, web3Instance: window.web3, chainId })
        } else {
          commit('INIT_LOGIN_INFO', { address, chainId })
        }
      })
      .catch(error => {
        console.error('error', error)
        if (error.code === 4001) {
          // EIP-1193 userRejectedRequest error
          //console.log("用户取消登录.");
          // this._vm.$toast.error(this._vm.$i18nn.t('wallet.toast.t1'))
        } else {
          console.error(error)
        }
      })
  },
  async initData ({ dispatch }) {
    let isLogin = localStorage.getItem('isLogin')
    if (isLogin) {
      dispatch('connectMetaMaskAction')
    }
  },
  signatureForLogin ({ commit }, payload) {
    let msg = 'This signature is used to verify your identity.'
    let web3 = window.web3
    let userState = JSON.parse(localStorage.getItem('userState'))
    if (userState) {
      web3 = window.web3Wallet
    }
    let code = web3.utils.utf8ToHex(msg)
    //console.log("utf8Hex：", code)
    //console.log(payload)
    let signature = web3.eth.personal
      .sign(code, payload.address)
      .then(receipte => {
        //console.log("out-this", this)
        // console.log(receipte);
        userLogin({ signature: receipte, address: payload.address, msg })
          .then(result => {
            let data = result.data
            let userInfo = data.data
            if (data.code === 1) {
              // "User login succeeded, return information：" + JSON.stringify(userInfo))
              if (!payload.isSwitch) {
                this._vm.$toast.success(this._vm.$i18nn.t('wallet.toast.t2'))
              } else {
                this._vm.$toast.success(this._vm.$i18nn.t('wallet.toast.t3'))
              }
              localStorage.setItem('userInfo', JSON.stringify(userInfo))
              // "token----",userInfo.token)
              localStorage.setItem('token', userInfo.token)
              commit('CONNECT_METAMASK', {
                address: payload.address,
                chainId: payload.chainId
              })
              commit('INIT_LOGIN_INFO', {
                address: payload.address,
                chainId: payload.chainId
              })
              commit('GET_USER_INFO_FROM_LOCAL_STORAGE')
            } else {
              // console.log( this._vm.$toast)
              // console.log(data.msg)
              this._vm.$toast.error(data.msg)
            }
          })
          .catch(error => {
            console.log('User login failed, error message：', error)
            //"User login failed, error message：", error);
            this._vm.$toast.error(this._vm.$i18nn.t('wallet.toast.t4'))
          })
      })
      .catch(error => {})
  }
}

const mutations = {
  //链接小狐狸钱包成功
  CONNECT_METAMASK (state, payload) {
    // debugger
    // console.log('CONNECTED_METAMASK-payload---', payload)
    let isLogin = localStorage.getItem('isLogin')
    if (isLogin) {
      return //如果已经登录过了，就不在往下走了
    }
    let netInfoCopy = state.netInfo
    netInfoCopy.address = payload.address
    // netInfoCopy.web3Instance = payload.web3Instance
    netInfoCopy.chainId = payload.chainId
    netInfoCopy.addressStr =
      payload.address.substr(0, 4) + '....' + payload.address.substr(38)
    state.netInfo = netInfoCopy
    state.isClose = false
    state.isLogin = true
    localStorage.setItem('isLogin', true)
    localStorage.setItem('chainId', netInfoCopy.chainId)
    localStorage.setItem('address', payload.address)
    localStorage.setItem('userInfo', JSON.stringify(netInfoCopy))
    // console.log('state，，，，', state)
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  },
  GET_USER_INFO_FROM_LOCAL_STORAGE (state) {
    let userInfo = localStorage.getItem('userInfo')

    if (userInfo) {
      userInfo = JSON.parse(userInfo)
      userInfo.addressStr =
        userInfo.address.substr(0, 4) + '....' + userInfo.address.substr(38)
      state.userInfo = userInfo
      state.isLogin = true
    }
  },
  SHOW_OR_HIDE (state, payload) {
    state.isClose = !state.isClose
  },
  LOGOUTED (state, payload) {
    state.netInfo = {}
    state.isLogin = false
    this._vm.$toast.success(this._vm.$i18nn.t('navMenus.other.logoutSuccess'))
    localStorage.removeItem('isLogin')
    localStorage.removeItem('chainId')
    localStorage.removeItem('currentNetwork')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('apiKey')
    setTimeout(() => {
      window.location.reload()
    }, 1500)
  },
  UPDATE_WEB3_INFO (state, payload) {
    let netInfoCopy = state.netInfo
    netInfoCopy.address = payload.address
    netInfoCopy.chainId = payload.chainId
    netInfoCopy.addressStr =
      payload.address.substr(0, 4) + '....' + payload.address.substr(38)
    localStorage.setItem('chainId', netInfoCopy.chainId)
    localStorage.setItem('address', payload.address)
    state.netInfo = netInfoCopy
    localStorage.setItem('userInfo', JSON.stringify(netInfoCopy))
  },
  INIT_LOGIN_INFO (state, payload) {
    // console.log('has login')
    let isLogin = localStorage.getItem('isLogin')
    if (isLogin) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo.address != payload.address) {
        userInfo.address = payload.address
        userInfo.addressStr =
          payload.address.substr(0, 4) + '....' + payload.address.substr(38)
        localStorage.setItem('address', payload.address)
      }
      let chainId = localStorage.getItem('chainId')
      if (!chainId || chainId != payload.chainId) {
        userInfo.chainId = payload.chainId
        localStorage.setItem('chainId', payload.chainId)
      }
      state.netInfo = userInfo
      state.isClose = false
      state.isLogin = true
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
      // console.log("INIT_LOGIN_INFO-state : ", JSON.stringify(state))
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
