<template>
  <div class="footer">
    <div class="footer-wrapper">
            
     <div class="link-top"></div>

      <div class="top-wrapper">
        <div class="left-wrapper">
          <!-- <div class="logo">
            <img src="@/assets/logo/logo.png" alt="" />
          </div> -->
          
          <div class="third">
            <div class="desc">
            {{ $t("footer.title.desc") }}
          </div>
            <div
              class="right-item"
              v-for="(item, index) in iconList"
              :key="index"
            >
              <span @click="goto(item.url)">
                <i class="iconfont ic">{{ item.icon }}</i>
              </span>
            </div>
          </div>
        </div>
        <div class="right-wrapper">
          <!-- <div class="menu-item">
            <div class="title">{{ $t("footer.title.about.t1") }}</div>
            <div class="item">{{ $t("footer.title.about.item_1") }}</div>
          </div> -->
          <!-- <div class="menu-item">
            <div class="title">社区</div>
            <div class="item">Privacy Policy</div>
            <div class="item">Terms and Conditions</div>
          </div> -->
          <!-- <div class="menu-item">
            <div class="title">Newsletter</div>
            <div class="item line">
              Sign up for our newsletter to get the latest news in your inbox.
            </div>
            <div class="item">
              <input type="text" placeholder="Your email address" />
            </div>
          </div> -->
          
        </div>
      </div>
      <div class="bottom-wrapper">@2024 TwitterUTools. All Right Reserved</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterNav",
  data() {
    return {
      iconList: [
        {
          icon: "#icon-tuitetwitter43",
          icon:"\ue9bd",
          url: "https://twitter.com/pWP5s7zypwvCwJp",
        },
        {
          icon: "#icon-discord",
          icon:"\ue60b",
          url: "https://discord.gg/VEKBj3fT9G",
        },
        {
          icon: "#icon-telegram",
          icon:"\ue715",
          url: "https://t.me/Yang0619",
        },
        {
          icon: "#icon-Emailus",
           icon:"\ue601",
          url: "email",
        },
      ],
    };
  },
  methods: {
    goto(url) {
      if (url == "email") {
        window.open("mailto:huojiecs@gmail.com", "_blank"); // 新窗口打开外链接
      } else {
        window.open(url, "_blank"); // 新窗口打开外链接
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  color: #47494e;
  text-decoration: none;
}
.footer {
  width: 100%;
  // margin-top: 50px;
  height: 200px;
  background: #0c122d;
  // position: absolute;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  min-width: 375px;
  .footer-wrapper {
    height: 100px;
    max-width: 1300px;
    // width: 60%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
    // line-height: 100px;
    margin: 0 auto;

    .top-wrapper {
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
      .left-wrapper {
        .logo {
          img {
            width: 250px;
            height: 46px;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
          }
        }
        .desc {
          margin-top: 10px;
          width: 379px;
          font-size: 16px;
          font-family: Gilroy-Regular-☞, Gilroy-Regular;
          font-weight: normal;
          color: #ffffff;
          line-height: 24px;
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
        }
        .third {
          margin-top: 20px;
          display: flex;
          .right-item {
            display: flex;
            margin-left: 60px;  
            .ic {
              font-size: 30px;
              // background: #FFFFFF;
              // border-radius: 0px 0px 0px 0px;
              // opacity: 1;
              color: #fff;
              margin-right: 40px;
            }
            cursor: pointer;
          }
        }
      }
      .right-wrapper {
        display: flex;
        justify-content: space-between;
        .menu-item {
          display: flex;
          flex-direction: column;
          .title {
            font-size: 16px;
            font-family: Gilroy-Regular-☞, Gilroy-Regular;
            font-weight: normal;
            color: #ffffff;
            line-height: 24px;
          }
          .item {
            margin-top: 10px;
            font-size: 16px;
            font-family: Gilroy-Regular-☞, Gilroy-Regular;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.67);
            line-height: 24px;

            input {
              width: 255px;
              height: 40px;
              background: #ffffff;
              border-radius: 8px 8px 8px 8px;
              opacity: 1;
            }
            input::placeholder {
              font-size: 14px;
              font-family: DM Sans-Regular, DM Sans;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6);
              line-height: 20px;
              position: relative;
              left: 20px;
            }
          }
          .line {
            width: 243px;
            font-size: 16px;
            font-family: DM Sans-Medium, DM Sans;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.6);
            line-height: 20px;
          }
        }
        .menu-item:nth-child(2) {
          margin: 0 80px;
        }
      }
    }
    /*中间的过度的横线*/
    .link-top {
      margin: 80px 0 20px 0;
      width: 100%;
      height: 1px;
      border-top: solid #fff 1px;
    }
    .bottom-wrapper {
      margin-top: 10px;
      font-size: 16px;
      font-family: DM Sans-Regular, DM Sans;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }

    // @media only screen and (max-width: 900px) {
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: flex-end;
    //   .left-wrapper {
    //     margin-bottom: 10px;
    //   }
    //   .right-wrapper {
    //     margin-bottom: 10px;
    //   }
    // }
  }
}
</style>